.staff-member-btn {
position: fixed;
bottom: 20px;
right: 0px;
z-index: 9;
}
.filter-type {
	padding-left: 2px !important;
	max-width: 330px !important;
}
@media screen and (max-width: 1279px) { 
.staff-member-btn .assign-staff-btn-outer button { width: auto; padding-left: 30px;  padding-right: 35px;}
}

@media screen and (max-width: 767px) {  
.staff-member-btn {
width: calc(100% - 40px);
right: 20px;
}
}

