.dashboard-content-area-header {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 15px;
	position: relative;
}
.header-section-wrapper-1 {text-align: end;padding:0;display: flex;position: relative;top:3px;}
.header-section-wrapper-2 {	margin-right: 22px;	margin-left: 29px;}
.header-section-wrapper-2 .dates-rail-wrapper{margin:0; position: relative; top:-6px;}
.header-section-wrapper-2 .dates-rail-wrapper .slick-list{position: relative; top:2px;}
.header-calendar-input-wrapper {position: absolute;left: -5px;}
.header-options-wrapper {display: flex;color: #727272;padding: 12px 0;width: 100%;}
.header-options-divider {margin: 0 20px;border: 1px solid;line-height: 18px;}
.header-section-wrapper-min > div{align-items: center;}
.header-section-wrapper-min.mobile-view{display: none;}
.head-section-2{flex-wrap: inherit; justify-content: space-between;}
.head-section-2 .header-links{width: auto;}
.head-section-2 .header-section-wrapper-min{display: flex !important; align-items: center;}
.head-section-2 .header-section-wrapper-min .header-links-right{margin-right: 24px;}
.head-section-3 .header-section-wrapper-min.mobile-view-without-link{display: none;}
.head-section-3 .header-section-wrapper-min.desktop-view{display: block;}
.head-section-4{flex-wrap: inherit; justify-content: space-between;}
.head-section-4 .mobile-view-without-link{display: none;}  
.head-section-1.head-section-notification{margin-bottom: 13px;}
.head-section-1.head-section-notification .header-section-wrapper-min.desktop-view{display: flex !important; align-items: center;}
.head-section-1.head-section-notification .header-section-wrapper-min.mobile-view{display:none !important;}
.head-section-1.head-section-notification .header-section-wrapper-min.desktop-view .header-links-right {	margin-right: 24px;}
.head-section-1.head-section-notification .header-section-wrapper-1 {	margin-right: 25px;	}
.head-section-1.head-section-notification .header-section-wrapper-1 .header-calendar-input-wrapper button{padding-bottom: 6px;}
.head-section-1.head-section-notification .header-section-wrapper-1 .header-calendar-input-wrapper button:hover {
    background-color: transparent;
}




.head-section-1 .header-section-wrapper-1 .header-calendar-input-wrapper button {border: 0 !important;}
.head-section-1 .header-section-wrapper-1 .header-calendar-input-wrapper button:hover {
    background-color: transparent !important;
}



.head-section-1 .header-section-wrapper-1 .header-calendar-input-wrapper button.MuiButtonBase-root .MuiIconButton-label {border:1px dashed #7f7f7f !important; padding:4px; border-radius: 50%; font-size: 1rem !important;}
.head-section-1 .header-section-wrapper-1 .header-calendar-input-wrapper button.MuiButtonBase-root .MuiIconButton-label .MuiSvgIcon-root{font-size: 1rem !important;}

@media screen and (max-width: 1279px) {
  .dashboard-content-area-header{position: relative;}
.header-section-wrapper-1 {  top:0;}
.header-section-wrapper-2 {	margin-right: 0px;	margin-left: 0;	width: 100%; margin-top: 15px;}
.header-section-wrapper-2 .dates-rail-wrapper {	margin: 0;	width: 100%;	max-width: inherit;}
.header-section-wrapper-min.mobile-view {	display: block;	margin-left: 23px;}
.header-section-wrapper-min.desktop-view{display: none;}

}
@media screen and (max-width: 767px) {
  .header-section-wrapper-1 {  	margin-right: 0; 	margin-top: 53px;  	width: 100%;  }
    .header-section-wrapper-1 .DayPickerInput {width: 100%;}
    .header-section-wrapper-1 .DayPickerInput input {width: 100%;}
    .header-section-wrapper-min.mobile-view {	position: absolute;	right: 0;	top: 2px;}
    .head-section-2 .header-section-wrapper-min {	position: absolute;	top: 2px;	right: 0;}
    .head-section-3 .header-section-wrapper-min.mobile-view-without-link{display: block;}
    .head-section-3 .header-section-wrapper-min.desktop-view{display: none;}
    .head-section-1.head-section-notification .header-section-wrapper-min.desktop-view{display: none !important;}
    .head-section-1.head-section-notification .header-section-wrapper-min.mobile-view{display:block !important;}

    }
