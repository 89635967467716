.job-details-id {
  color: white;
  padding: 8px;
  margin: 8px;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 12px;
}

.incomplete {
  background-color: #ff4141;
}

.in-progress {
  background-color: #ffaa2a;
}

.completed {
  background-color: #31ae45;
}

.job-details-info-list-title {
  color: #bbbbbb;
  font-size: 14px;
  text-transform: uppercase;
}

.job-details-info-list-wrapper {
  width: 33.3%;
  text-align: center;
}

.job-details-info-list-data {
  color: #7f7f7f;
  font-size: 14px;
}

.job-details-info-list-divider {
  width: 1px;
  height: 26px;
}
.jobs-details-utcs-wrapper {
  display: flex;
  flex-grow: 1;
}

.jobs-details-utcs-title {
  color: #bbbbbb;
  font-size: 12px;
}
.jobs-details-utcs-data {
  color: #7f7f7f;
  font-size: 12px;
  margin: 0 4px;
}

.jobs-details-list-tbc {
  background-color: #ffe100;
  color: #535353;
  font-size: 12px;
  letter-spacing: 0.01px;
  padding: 0px 8px;
  font-family: "futura-pt-bold";
  border-radius: 20px;
  margin: 16px 0 0 8px;
  max-width: 40px;
  min-width: 35px;
}

.job-details-info-list-divider {
  width: 0;
  border-right: 1px solid #bbbbbb;
  height: 26px;
  background-color: #bbbbbb;
  margin: 6px 0;
}

.job-details-view-job-report-wrapper {	height: 100%;	display: flex;	align-items: center;}
.job-details-view-job-report-wrapper a p {	color: #A0CB59 !important;	font-size: 14px;}
.job-list-toggle {    display: flex;    align-items: center;    margin-left: 8px;}
.list-title-area{display: flex;width: 300px;word-break: break-word;}

@media screen and (max-width: 1400px) {
  .list-title-area {  width: 230px;}
  .job-details-info-list-title {	font-size: 12px;}
  .job-details-info-list-data {	font-size: 12px;}
}
@media screen and (max-width: 1365px) {
  .list-title-area {  width: 200px;}
}
@media screen and (max-width: 1279px) {
.list-title-area {
    width: 100%;
}
}
