
.job-grid-outer {
	width: 100%;
	height: 100%;
	padding: 0 20px;
	border-radius: 8px;
	background-color: white;
	  position: relative;
	  font-family: 'futura-pt-medium';
	  font-size: 16px;
}

.job-grid-outer span{	
	  font-size: 14px;
}
.job-grid-id {
	padding: 5px 15px;
	border-radius: 8px 0 8px 0;
	font-size: 12px;
	position: absolute;
	left: 0;
	top: 0;
	min-width: 125px;
	color: #fff;
	text-transform: uppercase;
}
.job-grid-time {
	position: absolute;
	top: 6px;
	left: 140px;
	text-align: center;
}
.job-grid-time span{color:grey;}
.job-grid-toggle {
	position: absolute;
	right: 0;
	top: -9px;
}
.job-grid-content {
	padding-top: 51px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 100%;
}
.job-grid-title {
	text-align: left;
	font-size: 20px;
	line-height: 26px;
	color: rgb(83, 83, 83);
}
.grid-btm-first {
	padding: 0;
	display: flex;
  flex-wrap: wrap;
	width: 100%;
	list-style: none;
	font-size: 16px;
	margin-top: 20px;
  margin-bottom: 0;
	justify-content: space-between;
}
.grid-btm-first li{text-align: center; margin-bottom: 20px;}
.grid-btm-first li span{display: block; color:#bbb;text-transform: uppercase; font-size: 14px;margin-top: 8px;}
.grid-btm-first li span.text-green {
    display: inline-block;
    color: #8FBE3E;
    font-size: 14px;
    margin-top: 0;
}
.grid-btm-second {
	display: flex;
	justify-content: space-between;
	list-style: none;
	padding: 0;
	border-bottom: 1px solid rgba(146, 149, 154, 0.25);
	padding-bottom: 15px;
	margin-bottom: 0;
  position: relative;
	margin-top: 0;
}
.grid-btm-second::before {    position: absolute;    left: 50%;    top: 3px;    background: rgba(146, 149, 154, 0.25);    width: 1px;    height: 15px;    content: ""; }
.grid-btm-second li span{color:grey;}
.grid-btm-second li img {
    height: 14px;
    margin-right: 5px;
    position: relative;
    top: 2px;
}
.grid-btm-third {
	display: flex;
	justify-content: space-between;
  	padding: 15px 0;
}
.grid-btm-contact-left {
	display: flex;
}
.grid-btm-contact-left p {
	margin-right: 40px;
	display: flex;
	align-items: center;
	font-size: 14px;
}
.grid-btm-contact-left p img{margin-right: 8px; height: 28px;}
.grid-btm-contact-left p:last-child{ margin-right:0px;}

.grid-btm-status-right {
	border-left: 1px solid rgba(146, 149, 154, 0.25);
	display: flex;
	align-items: center;
}
.grid-btm-status-right .tbc-circle {
	background-color: #ffe100;
  width: 28px;
  height: 28px;
  line-height: 28px;
	color: #535353;
	font-size: 12px;
	letter-spacing: 0.01px;
	font-family: "futura-pt-bold";
	border-radius: 50%;-webkit-border-radius: 50%;-ms-border-radius: 50%;
	margin-left: 20px;
}
.day-status {
	margin-left: 20px;
}
.day-status img {
	height: 28px;
	width: 28px;
	display: block;
}




.job-details-id {
  color: white;
  padding: 8px;
  margin: 8px;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 12px;
}

.incomplete {
  background-color: #ff4141;
}

.in-progress {
  background-color: #ffaa2a;
}

.completed {
  background-color: #31ae45;
}

.job-details-info-title {
  color: #bbbbbb;
  font-size: 12px;
  text-transform: uppercase;
}

.job-details-info-client-area{padding-top:5px !important; padding-bottom:6px !important;}
.job-details-info-wrapper {
  width: 33.3%;
  text-align: left;
}

.job-details-info-data {
  color: #7f7f7f;
  font-size: 12px;
}
.job-details-info-data a {
  color: #7f7f7f;
  text-decoration: none;
}

.job-details-info-divider {
  padding: 0 8px;
  margin: 8px auto;
  width: calc(100% - 26px);
  height: 1px;
  background-color: rgba(146, 149, 154, 0.25);
}

.jobs-details-utcs-wrapper {
  display: flex;
  flex-grow: 1;
}
.jobs-details-utcs-outer .jobs-details-utcs-wrapper.jobs-details-utcs-wrapper-tbc {
  justify-content: flex-end;
}
.jobs-details-utcs-outer .jobs-details-utcs-wrapper .jobs-details-tbc{
  display: inline-block;
padding: 0 15px;
}

.jobs-details-utcs-title {
  color: #bbbbbb;
  font-size: 12px;
}
.jobs-details-utcs-data {
  color: #7f7f7f;
  font-size: 12px;
  margin: 0 4px;
  display: flex;
}

.jobs-details-tbc {
    background-color: #ffe100;
    color: #535353;
    font-size: 12px;
    letter-spacing: 0.01px;
    padding: 0px 8px;
    font-family: "futura-pt-bold";
    border-radius: 20px;
}

.job-details-view-job-report-wrapper {
  padding: 12px;
  color: green;
  cursor: pointer;
}
.view-job-report-right-link {
	padding: 5px 15px 25px 15px;
	justify-content: flex-end;
}
.view-job-report-right-link .jobs-details-utcs-title a p {
	color: #A0CB59 !important;
	font-size: 13px;
}
.grid-view-outer-block.block-space-between{display: flex; flex-direction: column; width: 100%; justify-content: space-between;}

@media screen and (max-width: 1400px) {
.view-job-report-right-link {padding:5px 15px 25px 15px;}
.job-grid-title p {  	font-size: 18px;  	margin-top: -5px;  }
.job-grid-content {	padding-top: 41px;}
.job-grid-title {	font-size: 18px;	line-height: 24px;}
.grid-btm-contact-left p {	margin-right: 20px;}
.grid-btm-second::before {display: none;	}
}
@media screen and (max-width: 1279px) {
  .job-grid-title p {  	font-size: 16px;  	margin-top: -7px;  	line-height: normal;  }
	.job-grid-title {	font-size: 16px;	line-height: 20px;}
}
@media screen and (max-width: 767px) {
  .jobs-details-utcs-outer{flex-wrap: wrap;}
  .jobs-details-utcs-outer .jobs-details-utcs-wrapper {
  width: 50%;
}
.job-grid-btm-area {
  position: relative;
}
.dash-box-outer .grid-view-outer-block {
 padding-bottom: 0 !important;
}
.jobs-details-utcs-outer .jobs-details-utcs-wrapper.jobs-details-utcs-wrapper-tbc {
  justify-content: flex-start;
  margin-top: 5px;
}
.grid-btm-second{flex-wrap: wrap;}
.grid-btm-second li{text-align: left; width: 100%;}
.grid-btm-second li:first-child{margin-bottom: 8px;}
.grid-btm-second li span{font-size: 12px}
}
