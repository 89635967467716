
.add-staff-signature-wrapper {
    padding: 15px 24px;
    text-align: left;
    background: white;
    width: 70% !important;
    border-radius: 12px;
    position: absolute;
}

.add-staff-registered-name {
    font-family: "futura-pt-demi";
    font-size: 16px;
    line-height: 21px;
    color: #535353;
    padding: 4px 0;
}

.add-staff-staff-name {
    font-family: "futura-pt-book";
    font-size: 16px;
    line-height: 21px;
    color: #535353;
    padding: 4px 0;
}

.add-staff-signature-inner-wrapper {
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    padding: 24px 0

}

.add-staff-signature-img-wrapper {
    width: 124px;
    height: 175px !important;
    margin: auto;
}

.add-staff-signature-img {
    width: 124px;
}

.address-section {
    text-align: left;
    cursor: pointer;
}

.address-suggestion {
    background-color: #fff;
    padding: 1px 5px 0;
    font-size: 12px;
    color: #7f7f7f;
}
.add-staff-page-drag{margin-top: -200px !important;}
.add-staff-radio-btn{padding-top: 6px;}
.add-staff-radio-btn .radio-custom-circle:nth-child(2){margin-left: 50px;}


.staff-form-btn button{ width: 100%;}
.staff-form-btn .anj-create-job-wrapper{display: flex; align-items: flex-end;}


.staff-action-button {
	width: 100%;
}

.staff-action-button {
	width: 100%;
}

.staff-action-button .MuiButtonBase-root {
	border-radius: 74px !important;
	padding: 0px 30px;
	line-height: 45px;
	height: 45px;
}

.staff-action-button .light-gray-btn:hover {
	background:#c6c6c6 !important;
}


@media screen and (max-width: 1400px) {
.add-staff-signature-wrapper {
            width: 100% !important;
    border-radius: 10px;
}
}



@media screen and (max-width: 1279px) {
  .add-staff-signature-wrapper {
  	position: relative !important;
  }
.add-staff-blank{display: none;}
.add-staff-page-drag {	margin-top: 0px !important;}

}
@media screen and (max-width: 991px) {
.staff-form-btn{margin-top: 10px !important;}
}

.text-danger {
    color: #FF4141;
    text-align: left;
  }

  .displayDiv {
  display: block;
  }
