.location-page-header {display: flex;}
.search-block-left {display: flex;margin-right: 32px;}
.search-loc-input{position: relative; max-width: 327px; width: 327px;}
.search-loc-input-inner{position: relative;} 
.search-loc-input-inner::after {position: absolute;top: 50%;content: "";width: 15px;height: 15px;background-size: 15px !important;background: url(/images/search-dark-gray.png) no-repeat;left: 15px;transform: translateY(-50%);-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);}
.search-loc-input input {width: 100%;height: 35px;border-radius: 25px;border: 1px solid #666;padding: 0 20px 0 40px;font-size: 15px;font-family: 'futura-pt-medium';outline: none !important;box-shadow: none !important;-webkit-appearance: none;-moz-appearance: none;color:#535353;}
.search-loc-input input::-webkit-input-placeholder { color: #535353;}
.search-loc-input input:-ms-input-placeholder { color: #535353;}
.search-loc-input input::placeholder { color: #535353;}
.search-loc-input input:focus{border-color:rgb(143, 190, 62);}
.search-block-left button {border-radius: 40px;font-size: 14px;color: #fff;height: 36px;line-height: 36px;background: #a0cb59 !important;padding: 0 25px;margin-left: 10px;}
.loc-tab-block {display: flex;}
.loc-tab-block a{ height: 36px; font-size: 14px; border-radius: 35px; background: #fff; color:#535353; padding:0 15px 0 40px;text-decoration: none; line-height: 36px; min-width: 108px; font-family: 'futura-pt-medium'; margin-right: 20px;}
.loc-tab-block a:last-child{margin-right: 0;}
.loc-tab-block a.active{ background: #a0cb59; color:#fff;}
.loc-tab-block a.tab-working{background-image: url(/images/location-working-icon.svg); background-size: 21px;  background-repeat: no-repeat; background-position: 12px center; }
.loc-tab-block a.tab-working:hover, .loc-tab-block a.tab-working.active{background-image: url(/images/location-working-active-icon.svg);}
.loc-tab-block a.tab-available{background-image: url(/images/location-available-icon.svg); background-size: 21px;  background-repeat: no-repeat; background-position: 12px center; }
.loc-tab-block a.tab-available:hover, .loc-tab-block a.tab-available.active{background-image: url(/images/location-available-active-icon.svg);}
.loc-tab-block a.tab-active-jobs{background-image: url(/images/location-active-jobs-icon.svg); background-size: 21px;  background-repeat: no-repeat; background-position: 12px center; }
.loc-tab-block a.tab-active-jobs:hover, .loc-tab-block a.tab-active-jobs.active{background-image: url(/images/location-active-jobs-active-icon.svg);}
.location-map{margin-top: 25px;}#map_wrapper {    height: 80vh;}
#map_canvas {    width: 100%;    height: 100%;}
.gm-style-iw.gm-style-iw-c {padding: 0 !important;}
.gm-ui-hover-effect {	right: 5px !important;	top: 3px !important; display: none !important}
.loc-grid-job {background: #fff;display: flex;flex-direction: column;justify-content: space-between;padding: 0 15px; width: 370px; max-width: 370px;}
.loc-job-grid-title {text-align: left;padding: 42px 0px 8px;font-size: 20px;line-height: 26px;color: rgb(83, 83, 83);}
.loc-job-details-info-client-area {padding-top: 5px !important;padding-bottom: 6px !important;display: flex; text-align: left; padding: 0px 0px;}
.loc-jobs-details-utcs-outer{display: flex; padding: 4px 0px 12px;}
.loc-grid-job .job-details-info-divider {	width: calc(100% - 0px);}
.loc-map-outer {position: relative;width: 100%;display: flex; height: calc(100vh - 112px); margin-top: 25px; max-width: 1440px; margin-left: auto; margin-right: auto;}
.loc-page-km-col {color: #535353;height: 26px;line-height: 26px;text-align: left;padding-left: 164px;}
.loc-page-km-col img {height: 10px;}
.loc-wtsap-col {position: absolute;right: 6px;top: 6px;}
.bg-green{background: #a0cb59 !important;}
.grid-title-outer.loc-grid-staff-title {margin-left: -10px;width: calc(100% + 20px);margin-bottom: 5px;flex-direction: inherit;} 
.gm-style .gm-style-iw-c, .gm-style-iw-d{max-width: 100% !important; max-height: inherit !important;}
.dash-box-outer.loc-grid-job .job-details-id{padding: 5px 15px;}
.search-loc-input .address-section{position: absolute; left:0; top:100%; z-index: 1; }
.search-loc-input .address-section .address-suggestion{padding:8px  10px;border-bottom:1px solid #ddd; color:#333;}
.search-loc-input .address-section .address-suggestion:hover{color:#fff; background: #a0cb59 !important;}

.custom-address-dropdown{position: relative;}
.custom-address-dropdown .address-section{position: absolute; left:0; top:100%; z-index: 1; width: 100%;}
.custom-address-dropdown .address-suggestion{padding:8px  10px;border-bottom:1px solid #ddd; color:#333;}
.custom-address-dropdown .address-suggestion:hover{color:#fff; background: #a0cb59 !important;}

@media screen and (max-width: 1279px) {
  .location-page-header {flex-wrap: wrap;}
.loc-tab-block {width: 100%;margin-top: 15px;margin-bottom: 10px;}
.search-loc-input{	width: 257px;}
.head-section-4 .mobile-view-without-link {	display: block;	position: absolute;	right: 0;	top: 2px;}
.loc-map-outer {	height: calc(100vh - 192px);}

}
@media screen and (max-width: 991px) {
	.loc-job-grid-title {	padding: 35px 0px 3px;	font-size: 16px; line-height: 20px;}
}
@media screen and (max-width: 767px) {
.search-block-left {	margin-right: 0;	width: 100%;	display: flex;	justify-content: space-between;}
.search-loc-input {	width: calc(100% - 10px);}
.location-page-header {	padding-top: 60px;	width: 100%;}
.loc-tab-block {	justify-content: space-between; display: flex;}
.loc-tab-block a {width: 33.33%;}
.search-block-left button{margin:0;}
.loc-tab-block a {font-size: 12px;padding: 0 10px 0 25px;min-width: inherit;margin-right: 5px;}
.loc-tab-block a.tab-working, .loc-tab-block a.tab-available, .loc-tab-block a.tab-active-jobs {background-size: 16px;}
.loc-tab-block a.tab-active-jobs {background-size: 13px;}
.loc-map-outer {	height: calc(100vh - 240px); margin-top: 15px;}
.loc-grid-job {width: 98%; max-width: 300px;}
.loc-map-outer .loc-grid-job {width:auto; max-width: 300px;}
.dash-box-outer.loc-grid-job .job-details-id{min-width: 100px; }  
.loc-page-km-col {	padding-left: 116px;	font-size: 12px;}
.loc-wtsap-col img {	width: 20px;	height: 20px; display: block;}
.grid-btm-second {flex-wrap: inherit;}
.gm-style .gm-style-iw-c {width:320px;}
}
