.job-details-id {
  color: white;
  padding: 8px;
  margin: 8px;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 12px;
}

.incomplete {
  background-color: #ff4141;
}

.in-progress {
  background-color: #ffaa2a;
}

.completed {
  background-color: #31ae45;
}

.pending {
  background-color: #707070;
}

.job-details-info-title {
  color: #bbbbbb;
  font-size: 12px;
  text-transform: uppercase;
}

.job-details-info-wrapper {
  width: 33.3%;
  text-align: left;
}

.job-details-info-data {
  color: #7f7f7f;
  font-size: 12px;
}

.job-details-info-divider {
  padding: 0 8px;
  margin: 8px auto;
  width: calc(100% - 26px);
  height: 1px;
  background-color: rgba(146, 149, 154, 0.25);
}

.jobs-details-utcs-wrapper {
  display: flex;
  flex-grow: 1;
}

.jobs-details-utcs-title {
  color: #bbbbbb;
  font-size: 12px;
}
.jobs-details-utcs-data {
  color: #7f7f7f;
  font-size: 12px;
  margin: 0 4px;
  display: flex;
}

.jobs-details-tbc {
    background-color: #ffe100;
    color: #535353;
    font-size: 12px;
    letter-spacing: 0.01px;
    padding: 0px 8px;
    font-family: "futura-pt-bold";
    border-radius: 20px;
}
.grid-colums-outer {
	margin-top: 5px !important;
}
.job-details-view-job-report-wrapper {
  padding: 12px;
  color: green;
  cursor: pointer;
}
.grid-date-right.staff-mem-page{padding-top: 4px;}
.grid-date-right .value-green, .job-details-info-title .value-green {color: rgb(143, 190, 62); margin-left: 5px;}
.grid-title-outer {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}
.assign-staff-btn-outer{justify-content: flex-end;}
.assign-staff-btn {
	color: white !important;
	width: 305px;
	font-size: 18px !important;
	font-family: futura-pt-demi !important;
	border-radius: 22px !important;
	background-color: #A3C960 !important;
}

@media screen and (max-width: 767px) {
  .grid-title-outer {  	flex-direction: column;  }
  .grid-title-outer .job-det-info-inline {		padding-left: 10px;}
.assign-staff-btn {	width: 100%;}
}
