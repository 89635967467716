.sidebar-logo{width: 140px;}
.dash-sidebar {padding-left: 0;list-style: none; }
.dash-sidebar li {	padding: 8px 15px;	display: block;	text-align: left;}
.dash-sidebar li a {display: block;position: relative;padding-left: 35px;color:#fff;}
.dash-sidebar li a svg {position: absolute;left: 0;top: 5px;fill:#ffffff;}   
.dash-sidebar li a svg path{fill:#fff;}
.dash-sidebar li a span{	font-size: 18px;color:#fff; font-family: 'futura-pt-medium';}
.dash-sidebar li:hover{background: rgba(0,0,0,.1);}
.dash-sidebar li:hover a span, .dash-sidebar li.active a span{color:#8FBE3E;}
.dash-sidebar li:hover a svg path, .dash-sidebar li.active a svg path{fill:#8FBE3E;}
.sidebar-close-icon {	position: absolute;	left: 100%;	top: 10px;	background: #333;	width: 20px;	height: 50px;	z-index: 1;	display: flex;	justify-content: center;	align-items: center;	border-top-right-radius: 3px;	border-bottom-right-radius: 3px;  display: none;}
.sidebar-close-icon svg {width: 14px;height: 14px;}
.sidebar-close-icon svg path {fill: #fff;}
@media screen and (max-width: 991px) {
  }
@media screen and (max-width: 767px) {
.sidebar-logo{width: 70%;}
.sidebar-close-icon{display: flex;}
.sidebar-close-icon+div {padding-top: 25px; padding-bottom: 20px; background: #333;}
.dash-sidebar {margin-top: 0;}
.dash-sidebar li {border-bottom: 1px solid rgba(255,255,255,.1);}
.dash-sidebar li a {padding-left: 30px;}
.dash-sidebar li a span {	font-size: 18px;}

}
