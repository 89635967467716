.add-staff-modal-over {
position: absolute;
/* margin-top: -55px; */
/* color: white; */
/* float: left; */
width: 100%;
display: flex;
justify-content: space-between;
padding-left: 2px;
padding-right: 2px;
}

.add-staff-modal-over p {
align-self: flex-end;
}

.add-staff-crew-wrapper {
background: white;
max-height: 159px;
overflow-y: auto;
}


.add-staff-search-input {
padding: 8px;
font-size: 14px;
font-family: "futura-pt-medium";
background: #f8f8f8;
outline: none;
border: unset;
width: 100%;
}
.add-staff-search-input::-webkit-input-placeholder { color: #535353;}
.add-staff-search-input:-ms-input-placeholder { color: #535353;}
.add-staff-search-input::placeholder { color: #535353;}

.add-staff-member-content-wrapper {
display: flex;
padding: 8px;
border-bottom: 1px solid #ececec;
}

.add-staff-member-name-wrapper {
padding: 8px;
width: 50%;
}

.add-staff-member-letter-wrapper {
padding: 0;
background: #e1e1e1;
border: 1px solid #d7d7d7;
border-radius: 25px;
font-family: "futura-pt-medium";
font-size: 22px;
margin: 0 4px;
cursor: pointer;
width: 35px;
height: 35px;
text-align: center;
line-height: 33px;
}
.add-staff-member-letter-wrapper:first-child{margin-left: 0;}

.add-staff-member-letters-wrapper {
display: flex;
margin: 0 0 0 auto;
width: 50%;
}
.dashboard-ljc {
display: flex;
align-items: center;
}
.dashboard-ljc .divider {
height: 1px;
}
.job-id-right {
background: #fff;
border: 1px solid #ddd;
font-size: 12px;
height: 24px;
border-radius: 10px;
padding: 0 20px;
text-align: center;
line-height: 24px;
font-family: 'futura-pt-medium';
}
.job-id-right span{color:#ccc;}
.add-staff-block-title > p{ font-size: 16px;color:#535353; margin-bottom: 5px;}

.member-select-outer {
display: flex;
flex-wrap: wrap;
}
.member-select-outer .mem-selected {
width: calc(50% - 10px);
justify-content: flex-start;
}
.member-select-outer .mem-selected svg{margin-left: auto;}
.makeStyles-active-643 {	background-color: #3399CC !important;	margin-right: 8px;}

.add-staff-modal-over.pop-title-custom-over {position: relative;height: 66px;line-height: 66px;padding: 0 25px;font-size: 22px;background: #4C4C4C;color: #fff;border-top-left-radius: 12px;border-top-right-radius: 12px; display: block;}
.add-staff-modal-over.pop-title-custom-over p + button {
background: none !important;
width: 32px;
height: 32px;
position: absolute;
right: 20px;
top: 50%;
transform: translateY(-50%);
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
padding:0 !important;
}
.add-staff-modal-over.pop-title-custom-over p + button svg{width: 100%; height: 100%;}
.add-staff-modal-over.pop-title-custom-over+.MuiDialogTitle-root{padding-bottom: 0;}
.add-staff-member-add-job {	display: flex;	align-items: center;	padding: 0 10px;}
.add-staff-member-add-job p a{color: rgb(143, 190, 62); text-decoration: none;}
.unavailable-area {	display: flex;	align-items: center;	margin-left: auto;	width: 50%;}
.assign-staff-col{padding:0 25px; width: 50%;}
.assign-staff-col:first-child{padding-left: 0;}
.assign-staff-col:nth-child(2){padding-right: 0;}
.add-job-green-cirlce{background: rgb(143, 190, 62) !important; color:#fff !important; border-color: rgb(143, 190, 62) !important; }

/* Custom select */
.custom-select {  position: relative; z-index: 2;}
.custom-select-toggle {background: #fff;border-radius: 25px;height: 36px;line-height: 36px;padding: 0 110px 0 20px;color: #535353;text-decoration: none;font-family: futura-pt-medium;position: relative;z-index: 1;position: relative;white-space: nowrap;display: flex;}
.custom-select-toggle span {white-space: nowrap;text-overflow: ellipsis;overflow: hidden;width: 100%; padding-left: 20px;}
.custom-select-toggle span::before {background: url(/images/team-leader-icon-gray.svg) no-repeat center center;background-size: 13px;width: 13px;height: 18px;position: absolute;left: 20px;top: 50%;content: "";
transform: translateY(-50%);-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);}
.custom-select-toggle.selected span::before {background: url(/images/team-leader-icon.svg) no-repeat center center;background-size: 13px;width: 13px;height: 18px;}
.custom-select-toggle.selected{color: #535353;}
.custom-select-toggle::after {-webkit-appearance: none !important;-moz-appearance: none !important;appearance: none !important;background: url(/images/select-arrow-black.png) no-repeat center center;background-size: 11px;width: 11px;height: 6px;position: absolute;right: 20px;top: 50%;content: "";
transform: translateY(-50%);-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);}
.custom-select-toggle.selected::after {content: "Edit Leader";color: #7F7F7F;font-size: 12px;width: auto;height: auto;background: none;right:44px;}
.custom-select-toggle.selected::before {background: url(/images/edit-icon-gray.svg) no-repeat center center;background-size: 16px;width: 16px;height: 16px;position: absolute;right: 20px;top: 50%;content: "";
transform: translateY(-50%);-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);}
.custom-select-value {
	background: #fff;
	display: block;
	position: relative;
	left: 0;
	top: 0px;
  margin-top: -18px;
	width: 100%;
	padding-top: 20px;
	border-bottom-left-radius: 25px;
	border-bottom-right-radius: 25px;
	opacity: 0;
	visibility: hidden;
	height: 0;
	transition: all .3s ease;
	-moz-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;

}
  .custom-select.open .custom-select-value{opacity: 1;  visibility: visible;  height: auto; transition:all .3s ease;-moz-transition:all .3s ease;-webkit-transition:all .3s ease;-ms-transition:all .3s ease;-o-transition:all .3s ease; }
.custom-select-value ul {padding: 0;margin: 0;list-style: none;	max-height: 170px;}
.custom-select-value ul li {padding: 8px 20px;border-top: 1px solid #D8D8D8;}
.custom-select-value ul li a {color: #535353;font-size: 14px;font-family: futura-pt-medium;text-decoration: none;}
.custom-select-error {	color: #FF4141;	font-size: 12px;	margin-top: 8px;}  
.assign-team-leader-select {margin-top: 15px;  }
/* Custom select end*/
@media screen and (max-width: 991px) {
.add-staff-modal-over.pop-title-custom-over {	height: 55px;line-height: 55px;	font-size: 18px;}
.assign-staff-col {	width: 60%; padding: 0 10px;}
.assign-staff-col:nth-child(2) {	width: 40%;}
.member-select-outer .mem-selected {	width: calc(100% - 0px);	margin-right: 0;}
}
@media screen and (max-width: 767px) {
.dashboard-ljc {flex-direction: column;}
.add-staff-modal-over.pop-title-custom-over {height: auto;line-height: normal;font-size: 15px;padding: 10px 35px 10px 20px;}
.add-staff-modal-over.pop-title-custom-over p + button {width: 22px;height: 22px;right: 10px;}
.assign-staff-col {	width: 100%;	padding: 0;}
.assign-staff-col:nth-child(2) {margin-top: 20px; width: 100%;}
.add-staff-member-content-wrapper {display: flex;padding: 8px;border-bottom: 1px solid #ececec;flex-wrap: wrap;}
.add-staff-member-name-wrapper {	width: 100%;}
.add-staff-member-letters-wrapper {	width: 100%;}
.unavailable-area {	width: 100%;	padding: 0 8px;}
}
