body {margin: 0;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif; -webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
code {font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;}

.check-custom input[type="checkbox"]{display:none;}
.check-custom input[type="checkbox"] + label{background:rgba(0,0,0,0) url('/images/gray-trans-check.png') no-repeat left top;font-weight:300;line-height:14px;margin:0;min-height:14px;min-width:14px;padding:0 0 0 25px; margin-right: 10px;}
.check-custom input[type="checkbox"]:checked + label{background-image:url('/images/gray-trans-check-checked.png');}
.radio-custom input[type="radio"]{display:none;}
.radio-custom input[type="radio"] + label{background:rgba(0,0,0,0) url('/images/white-trans-check.png') no-repeat left top;font-weight:300;line-height:14px;margin:0;min-height:14px;min-width:14px;padding:0 0 0 25px; margin-right: 10px;}
.radio-custom input[type="radio"]:checked + label{background-image:url('/images/gray-trans-check-checked.png');}

.radio-custom-circle input[type="radio"]{display:none;}
.radio-custom-circle input[type="radio"] + label{background:rgba(0,0,0,0) url('/images/custom-radio-circle.png') no-repeat left top;font-weight:300;line-height:22px;margin:0;min-height:22px;min-width:22px;padding:0 0 0 30px; margin-right: 10px;}
.radio-custom-circle input[type="radio"]:checked + label{background-image:url('/images/custom-radio-circle-checked.png');}

.custom-scroll{ overflow: auto; scrollbar-color: rgba(0,0,0,.3) rgba(0,0,0,.2) ; scrollbar-width: thin;}
.custom-scroll::-webkit-scrollbar { width: 2px;}
.custom-scroll::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,1); }
.custom-scroll::-webkit-scrollbar-thumb { border-radius: 50%; -webkit-box-shadow: inset 0 0 2px rgba(100, 221, 187,0.7); background: rgba(100, 221, 187,0.7); width: 5px; height: 5px; display: inline-block;}

.text-left{text-align: left;}
.text-darkgreen{color:#31ae45;}
.text-orange{color: #ffaa2a;}
.text-red{color: #ff4141;}
.padd0{padding: 0 !important;}






.MuiDialog-paper.MuiPaper-rounded {	border-radius: 14px !important; }
.MuiGrid-spacing-xs-4 {width: calc(100% + 55px) !important;margin: 0 -30px !important;}
.MuiGrid-spacing-xs-4 > .MuiGrid-item {	padding: 16px 30px !important;}



.uploaded-block {background: #fff;border-radius: 16px;display: flex;height: auto;justify-content: space-between;align-items: center;padding: 0 10px 0 15px;color:#535353;}
.uploaded-block p {text-align:left; background: url(/images/pdf-icon.png) left top 10px no-repeat;display: inline-block;background-size: 11px;padding: 5px 0 5px 20px;}
.uploaded-block a img{width: 20px; display: block;}
.form-btm-btn button{max-width: 300px; width: 100%; }


.qualificatons-upload-block .qualificaton-upload-col{background: #fff;border-radius: 50px;display: flex;height: 36px;justify-content: space-between;align-items: center;padding: 0 10px 0 10px;color:#535353; margin-bottom: 20px;}
.qualificatons-upload-block .qualificaton-upload-col p {display: inline-block;background-size: 11px;padding: 5px 0 5px 10px;}
.qualificatons-upload-block .qualificaton-upload-col a svg{display: block;}

.qualificatons-upload-block .qualificaton-uploaded-col{background: #4C4C4C;border-radius: 50px;display: flex;height: 36px;justify-content: space-between;align-items: center;padding: 0 10px 0 10px;color:#fff; margin-bottom: 20px;}
.qualificatons-upload-block .qualificaton-uploaded-col p {background: url(/images/green-check-circle.svg) left center no-repeat;display: inline-block;background-size: 24px;padding: 5px 0 5px 34px;}
.qualificatons-upload-block .qualificaton-uploaded-col a svg{display: block; }
.page-main-title .dashboard-ljc{padding:0 ;}


.header-links {	padding: 5px 0 5px 0px !important;	width: 100%;	justify-content: space-between;}
.header-links-left {	display: flex;}
.header-links a{align-items: center;}
.header-links button a{color:#a3c960;}
.header-links button.MuiButton-root:hover{background-color: transparent !important;}

.header-links-right{text-align: left;}
.header-date-block {	font-size: 12px;	position: relative;	top: 0px;	color: #7F7F7F;	font-family: 'futura-pt-medium';}
.no-record-found{margin:25px 0;}
.no-record-found p {
	font-size: 16px;
  width: 100%;
padding: 25px 8px;
border-radius: 8px;
background-color: white;
}
.grid-main-outer{ margin-top: 25px;}

.owl-theme .owl-nav {	margin-top: 0;}
.owl-theme .owl-nav button span {	font-size: 33px;	color: #666;}
.owl-theme .owl-nav [class*="owl-"]:hover {	background: none;	color: inherit; outline: none;}

.text-daner, .text-daner{color:#FF4141 !important;}





button.MuiButton-root:hover {
    text-decoration: none;
    background-color: #76A052 !important;
}

.add-job-new-button a button {border-radius: 8px !important;}


.grids-outer-common-css-full {max-width: 1440px; margin-left:auto; margin-right:auto;}


@media screen and (max-width: 1279px) {
.form-blank-div-uploaded{display: none;}
.form-btm-btn{ margin-top: 55px !important;}
.form-btm-btn button{max-width: 100%;}
.MuiGrid-spacing-xs-4 {	width: calc(100% + 48px) !important;}
}
@media screen and (max-width: 1279px) {
.header-links {
	padding: 5px 0 5px 0px !important;
}
}
@media screen and (max-width: 767px) {
  .header-date-block{display: none;}
  .header-links {	padding-left: 0px !important;	margin-top: 45px;}
  .no-record-found {	margin: 5px 0;}
  .no-record-found p {	font-size: 14px;		padding: 10px 8px;	}
}
