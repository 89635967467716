/* Dragimage popup start */
.popupoverflowAuto{overflow: inherit !important;}
.add-qualifcation-pop-outer {display: flex;}
.add-qualifcation-pop-left {background: #fff;display: flex;align-items: center;width: 45%;position: relative;min-height: 340px;}
.add-qualifcation-pop-left  .drag-drop-wrapper{position: absolute;left: 0;width: 100%;top: 0;height: 100%;}
.add-qualifcation-pop-left  .drag-drop-wrapper .add-new-job-field-title{display: none;}
.add-qualifcation-pop-left  .drag-drop-wrapper .dragOuterPopup, .add-qualifcation-pop-left  .drag-drop-wrapper .dragOuterPopup .drag-n-drop-box{width: 100%;height: 100%;}
.add-qualifcation-pop-left  .drag-drop-wrapper .dragOuterPopup .drag-n-drop-box .drag-n-drop-text-wrapper{opacity: 0;} 
.dragged-thumb {position: absolute;top: 0;left: 0;width: 100%;height: 100%;text-align: center;z-index: 1;align-items: center;display: flex;justify-content: center;}
.dragged-thumb img {	position: absolute;	left: 50%;	top: 45%; height: 100%; max-width: 100%; transform: translate(-50%, -50%);-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);}
.dragged-thumb.dragged-thumb-file-preview {position: absolute;top: 0;left: 0;width: 100%;height: 100%;text-align: center;z-index: 1;}
/* .dragged-thumb.dragged-thumb-file-preview img{ width: 100%; height: 100%; display: block;} */
.dragged-thumb.dragged-thumb-file-preview img{  display: block; object-fit: contain;}
.dragged-thumb a {position: absolute;bottom: 0;left: 0;width: 100%;text-align: center;color: #fffd;text-decoration: none;text-transform: uppercase;background: rgba(0,0,0,.5);padding: 15px 0;font-family: 'futura-pt-medium';}
.add-qualifcation-pop-right {width: 55%;padding: 15px 30px 66px 30px;position: relative;} 
.add-quali-inp-block {margin-bottom: 20px;}
.add-quali-btn {text-align: right;position: absolute;bottom: 25px;right: 30px;}
.add-quali-btn button {margin-bottom: 0 !important;margin-top: 0px !important;min-width: 210px !important; font-family: 'futura-pt-medium';} 
/* Dragimage popup end */

@media screen and (max-width: 991px) {
.add-qualifcation-pop-left {  min-height: 250px;}
}

@media screen and (max-width: 767px) {
.add-qualifcation-pop-outer {   flex-wrap: wrap;}
.add-qualifcation-pop-left {  min-height: auto;  width: 100%; }
.dragged-icon{position: relative; }
.dragged-thumb {  position: relative;  height: 180px;}
.dragged-thumb img {width: auto;} 
.dragged-thumb a {	padding: 10px 0;	font-size: 10px;}
.dragged-thumb.dragged-thumb-file-preview {	position: relative;	height: 180px;}
.dragged-thumb.dragged-thumb-file-preview img{ width: 100%; height: 100%; }
.add-qualifcation-pop-right {width: 100%;padding: 15px 20px 15px 20px;}
.add-quali-inp-block {  margin-bottom: 10px;}
.add-quali-btn {   position: static; }
.add-quali-btn button {margin-bottom: 15px !important;margin-top: 15px !important;width: 100%;}
.popupoverflowAuto { overflow-y: auto !important; -webkit-overflow-scrolling: touch;}
}

@media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) {
.popupoverflowAuto { overflow-y: auto !important; -webkit-overflow-scrolling: touch;}
}