.divider{  flex-grow: 1;  border-bottom: 1px solid gray;  margin: 0 12px;  height: 18px;  color: gray;}
.search-job-input{position: relative;}
.search-job-input::after {	position: absolute;	top: 50%;	content: "";	width: 15px;	height: 15px;	background-size: 15px !important;	background: url(/images/search-gray.png) no-repeat;	right: 15px; transform: translateY(-50%);-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);}
.search-job-input input {  max-width: 327px;  width:327px;  height: 35px;  border-radius: 25px;  border: 1px solid #666;  padding: 0 45px 0 20px;  font-size: 15px;  font-family: 'futura-pt-medium'; outline: none !important; box-shadow: none !important;
-webkit-appearance: none;   -moz-appearance: none;}
.search-job-input input:focus{border-color:rgb(143, 190, 62);}
.search-job-input input::-webkit-input-placeholder { color: #C8C8C8;}
.search-job-input input:-ms-input-placeholder { color: #C8C8C8;}
.search-job-input input::placeholder { color: #C8C8C8;}
@media screen and (max-width: 1279px) {
.search-job-input input {	width: 200px;}
}
@media screen and (max-width: 767px) {
.search-job-input-outer {font-size: 18px;flex-direction: column;justify-content: start;text-align: left;}
.search-job-input-outer .divider {margin: 0;height: 18px;margin-bottom: 20px;}
.search-job-input input {width: 100%;max-width: inherit;}


}
