.alternate-email-popup {
	padding: 10px 25px 20px 25px;
	background: #fff;
}
.alternate-input-outer {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}
.alternate-input {
width: 100%;
  padding-right: 40px;
}
.alternate-input input{border:1px solid #ECECEC; box-shadow: none; outline: none; 
outline: none;    
    -webkit-appearance: none; 
    -moz-appearance: none;
}
.alternate-input-outer button { 
	min-width: 210px;
	margin: 0 !important;
}
.alternate-email-popup .error-message{margin-top: 10px;}


@media screen and (max-width: 992px) {
  .alternate-input {
  	padding-right: 20px;
  }
  .alternate-input-outer button {
  	min-width: 160px;
  }
}
@media screen and (max-width: 767px) {
  .alternate-email-popup {
	padding: 10px 15px 20px 15px;
}
  .alternate-input-outer {
	flex-wrap: wrap;
}
.alternate-input {
	width: 100%;
	padding-right: 0;
}
.alternate-input-btn {
	width: 100%;
	margin-top: 15px;
}
.alternate-input-outer button {
	min-width: 100%;
}
}
