.add-staff-signature-wrapper {
	padding: 15px 24px;
	text-align: left;
	background: white;
	width: 225px;
	border-radius: 12px;
	position: absolute;
  }
  .add-staff-registered-name {
	font-family: "futura-pt-demi";
	font-size: 16px;
	line-height: 21px;
	color: #535353;
	padding: 4px 0;
  }
  .add-staff-staff-name {
	font-family: "futura-pt-book";
	font-size: 16px;
	line-height: 21px;
	color: #535353;
	padding: 4px 0;
  }
  .add-staff-signature-inner-wrapper {
	border: 1px solid #E6E6E6;
	border-radius: 10px;
	padding: 24px 0
  }
  .add-staff-signature-img-wrapper {
	width: 124px;
	height: 74px;
	margin: auto;
  }
  .add-staff-signature-img {
	width: 124px;
  }
  .address-section {
	text-align: left;
	cursor: pointer;
  }
  .address-suggestion {
	background-color: #fff;
	padding: 1px 5px 0;
	font-size: 12px;
	color: #7f7f7f;
  }
  .text-danger {
	color: #FF4141;
	text-align: left;
  }
  .displayDiv {
  display: block;
  }
  .client-sub-btn button {
	width: 100%;
  }
  
  .dash-box-outer.list-view .list-view-outer-block .list-table-area {
	  padding-right: 20px !important;
	  align-items: center;
  }
  .list-view-outer-block{width: 100%;padding: 0 8px;border-radius: 8px;background-color: white;position: relative;}
  
  .list-view-outer-block .job-report-stock-left-div {padding-left: 8px; padding-right: 8px;}
  
  
  
  .client-sub-btn{margin-top: 30px;}
  .bg-gray {background-color: #707070;  }
  .job-report-btn-outer{margin-top: 60px !important;}
  .job-report-btn button {min-width: 300px;}
  .anj-create-job-inner-wrapper {	position: inherit !important;	text-align: right;}
  .job-details-info-list-wrapper p a{color: rgb(143, 190, 62) !important; text-decoration: none;}
  
  .permit-slide-block-outer {
	  width: 100%;
	  position: relative;
	  padding: 0 30px;
  }
  .permit-slide-block {
	  background: #fff;
	  display: flex;
	  height: 36px;
	  justify-content: space-between;
	  align-items: center;
	  padding: 0 10px 0 15px;
	  color: #535353;
	  border-radius: 5px;
	  width: 100%;
  }
  .permit-slide-block p {
	  background: url(/images/pdf-icon.png) left center no-repeat;
	  display: inline-block;
	  background-size: 11px;
	  padding: 5px 0 5px 20px;
  }
  .permit-slide-block span {
	  background: rgb(143, 190, 62) !important;
	  color: #fff;
	  font-size: 10px;
	  text-transform: uppercase;
	padding: 1px 5px;
	border-radius: 10px;
  }
  
  .permit-slide-block-outer .owl-theme .owl-nav {
	  margin-top: -45px;
	  display: flex;
	  justify-content: space-between;
	  margin-left: -25px;
	  width: calc(100% + 50px);
  }
  .permit-slide-block-outer .owl-theme .owl-nav button{margin:0;}
  
  .job-permit-tgs-col {
	  display: flex;
	  justify-content: space-between;
	  margin-top: -20px !important;
  }
  .job-permit-col {
	  display: flex;
	  flex-wrap: wrap;
	  width: calc(100% + 40px);
	  margin-left: -40px;
  }
  .job-permit-col .permit-slide-block {
	  width: calc(50% - 40px);
	  margin-left: 40px;
	  margin-bottom: 10px;
	  border-radius: 25px;
  }
  .job-permit-col .permit-slide-block:last-child, .job-permit-col .permit-slide-block:nth-last-child(2){margin-bottom: 0;}
  .padd-b0{padding-bottom: 0 !important;}
  .left-stock-list {
	  display: flex;
	  flex-wrap: wrap;
	  margin-left: -20px;
	  width: calc(100% + 20px);
  }
  .left-stock-list .left-stock-col {
	  background: #fff;
	  border-radius: 25px;
	  min-width: 100px;
	  height: 32px;
	  line-height: 32px;
	  margin-left: 20px;
	  box-shadow: 0 0 5px rgba(0,0,0,.1);
	  display: flex;
	  align-items: center;
	  font-family: 'futura-pt-medium';
	  color: #494848;
	  margin-bottom: 20px;
	  padding-right: 12px;
  }
  .left-stock-list .left-stock-col span {
	  background: #727272;
	  border-radius: 50%;
	  -webkit-border-radius: 50%;
	  -ms-border-radius: 50%;
	  width: 25px;
	  height: 25px;
	  text-align: center;
	  line-height: 24px;
	  color: #fff;
	  margin-left: 4px;
	  margin-right: 8px;
  }
  
  .job-details-info-list-button {text-align:right;}
  .list-title-area {width: 200px;}
  @media screen and (max-width: 1279px) {
	.job-permit-col {
		width: calc(100% + 20px);
		margin-left: -20px;
	}
	.job-permit-col .permit-slide-block {
		width: calc(100% - 20px);
		margin-left: 20px;
	}
  .job-permit-col .permit-slide-block:nth-last-child(2) {
	  margin-bottom: 10px;
  }
  }
  @media screen and (max-width: 767px) {
  .job-permit-tgs-col {
	  flex-wrap: wrap;
  }
  .job-permit-tgs-inner:first-child {
	  margin-bottom: 20px;
  }
  .job-report-btn button {min-width: 100%;}
  }
  