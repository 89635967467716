.login-submit-button-wrapper button{
  color: white;
  font-size: 18.2px;
  font-family: futura-pt-demi;
  margin-top: 0; margin-bottom: 0;
}

@media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) {
.loginContainer.forgot-pass-container .MuiContainer-root {padding-top: 30px;padding-bottom: 30px;}
.loginContainer.forgot-pass-container {	min-height: 100vh !important; overflow: inherit !important;}
.loginContainer.forgot-pass-container .MuiContainer-root {min-height: 100vh !important;}
}
