.job-details-id {
  color: white;
  padding: 8px;
  margin: 8px;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 12px;
}

.incomplete {
  background-color: #ff4141;
}

.in-progress {
  background-color: #ffaa2a;
}

.completed {
  background-color: #31ae45;
}



.job-det-info-inline .job-details-info-wrapper {
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 25px;
}
.job-det-info-inline .job-details-info-wrapper:last-child{margin-right: 0;}
.job-det-info-inline .job-details-info-title {
  color: #bbbbbb;
  text-transform: uppercase;
  margin-right: 8px;
}
.job-det-info-inline .job-details-info-data {
	color: #535353;
	font-size: 14px;
}
.job-det-info-inline .job-details-info-wrapper:last-child .job-details-info-data{
  color: rgb(143, 190, 62);
}
.job-details-info-divider {
  padding: 0 8px;
  margin: 8px auto;
  width: calc(100% - 26px);
  height: 1px;
  background-color: rgba(146, 149, 154, 0.25);
}

.jobs-details-utcs-wrapper {
  display: flex;
  flex-grow: 1;
}

.jobs-details-utcs-title {
  color: #bbbbbb;
  font-size: 12px;
}
.jobs-details-utcs-data {
  color: #7f7f7f;
  font-size: 12px;
  margin: 0 4px;
  display: flex;
}

.jobs-details-tbc {
    background-color: #ffe100;
    color: #535353;
    font-size: 12px;
    letter-spacing: 0.01px;
    padding: 0px 8px;
    font-family: "futura-pt-bold";
    border-radius: 20px;
}

.job-details-view-job-report-wrapper {
  padding: 12px;
  color: green;
  cursor: pointer;
}

.client-title-section {
	display: flex;
	justify-content: space-between;
	padding: 15px 130px 10px 10px; 
	align-items: center;
}
.client-title-section p {
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: left;
  position: relative;
  line-height: normal;
}
.client-title-section a {
  position: absolute;
  right: 15px;
  top: 17px;
}
.client-title-section p img {
	height: 18px;
	margin-right: 6px;
}
    .client-title-section a p{ font-size: 18px;}



    .bottom-links-with-icon {
	display: flex; 
	justify-content: space-between;
	width: 100%;
	align-items: center;
}
.bottom-links-col{	display: flex; align-items: center;}
.bottom-links-col img {	height: 24px;	margin-right: 5px;}
.bottom-links-col a{font-size: 12px; color:#535353 !important;font-family: 'futura-pt-medium'; text-decoration: none;} 
.grid-date-right p{color:#bbbbbb;}
.id-text-light{color:rgba(255,255,255,.83);}

@media screen and (max-width: 767px) {
  .job-det-info-inline {
  	flex-direction: column;
  }
  .job-det-info-inline .job-details-info-wrapper {
	margin-right: 0;
	margin-bottom: 5px;
}
.job-det-info-inline .job-details-info-wrapper:last-child {
	margin-right: 0;
  margin-bottom: 0;
}
}
