.tbc-dates-rail-wrapper {text-align: left;width: 100%;margin: auto;}
.tbc-dates-date-wrapper {background-color: #a3c960;padding: 6px 10px;color: white;border-radius: 20px;}
.tbc-date-chk-box .MuiFormControlLabel-root{margin:0 !important;}

.add-new-job-dates-picker-wrapper {  position: relative;}
.add-new-job-dates-picker-wrapper .slick-list .slick-track {	display: flex;	justify-content: flex-start;	margin: 0;} 
.picker {  width: 100%;   background-color: white;  border-radius: 8px; padding-bottom: 20px;}    
.add-new-job-dates-picker-wrapper .DayPicker{ width: 100%; 	padding: 0 15px;}
.add-new-job-dates-picker-wrapper .DayPicker-Month{width: 100%;}
.add-new-job-dates-picker-wrapper .DayPicker-Months .DayPicker-Month{margin-left: 0 !important; margin-right: 0 !important;}


.add-new-job-dates-picker-wrapper .tbc-dates-rail-wrapper > p{padding-left: 38px !important;}
.select-tbc-col-outer {display: flex !important;justify-content: center !important;flex-direction: column;text-align: center;padding: 0 12px;}
.date-inCOntainer .slick-prev{top: 7px;left:10px !important;transform: inherit !important;}
.date-inCOntainer .slick-next {top: 7px;right:10px !important;transform: inherit !important;}
.date-inCOntainer .slick-prev::before {content: "";background: url(/images/left-arrow.svg)no-repeat;width: 10px !important;height: 20px !important;display: inline-block;background-size: 10px;}
.date-inCOntainer .slick-next::before {content: "";background: url(/images/right-arrow.svg)no-repeat;width: 10px !important;height: 20px !important;display: inline-block;background-size: 10px;}
.add-new-job-dates-picker-wrapper .DayPicker-WeekdaysRow {display: flex;width: 100% !important;justify-content: space-between;}
.add-new-job-dates-picker-wrapper .DayPicker-Month{flex-direction: column;display: flex;}
.add-new-job-dates-picker-wrapper .DayPicker-Weekday {padding: 0;width: 45px;}
.add-new-job-dates-picker-wrapper .DayPicker-Week {display: flex;  justify-content: space-between;width: 100%;align-items: center; margin:5px 0;}
.add-new-job-dates-picker-wrapper .DayPicker-Day {width: 45px;height: 45px;padding: 0;line-height: 45px;}


@media screen and (max-width: 1400px) {
.select-tbc-col-outer {	padding: 0 3px;}
.tbc-dates-date-wrapper {	padding: 4px 6px;	font-size: 12px;}
.date-inCOntainer .slick-prev {	top: 3px;	left: 4px !important;}
.date-inCOntainer .slick-next {	top: 3px;	right: 4px !important;}
.add-new-job-dates-picker-wrapper .tbc-dates-rail-wrapper > p {	padding-left: 30px !important;}  
.add-new-job-dates-picker-wrapper .DayPicker-Weekday {width: 38px;} 
.add-new-job-dates-picker-wrapper .DayPicker-Day {width: 38px;height: 38px;line-height: 38px;font-size: 12px;}   
}

@media screen and (max-width: 767px) {
.add-new-job-dates-picker-wrapper .DayPicker-Day {
	width: 34px;
	height: 34px;
	line-height: 34px;
	}
}