.dashboard-ljc {  display: flex;  padding: 12px 0;  font-size: 22px;  color: #535353;}
.slick-slide div, .slick-slide div:focus{  outline: none;}
.grid-title {text-align: left;font-size: 20px;line-height: 26px;color: rgb(83, 83, 83);font-family: 'futura-pt-medium';margin-top: 20px;padding: 0 8px;margin-bottom: 10px;}
.grid-date-right {font-size: 14px;margin-left: auto;width: 100%;text-align: right;color: rgb(83, 83, 83);font-family: 'futura-pt-medium';padding-top: 8px;padding-right: 20px;}
.grid-date-right p {display: flex;justify-content: flex-end;}
.grid-date-right p img {height: 18px;margin-right: 5px;}
.job-report-link a p{ font-size: 14px;color:#A3C960;}
.grid-colums-outer {	margin-top: 15px; margin-bottom: 15px; max-width: 1440px; margin-left:auto; margin-right:auto;}
@media screen and (max-width: 1400px) {
.grid-title {	font-size: 18px;	margin-top: 15px;}
}
@media screen and (max-width: 1279px) {
.grid-title {	font-size: 16px; margin-top: 10px;	}
}
@media screen and (max-width: 767px) {
.grid-colums-outer {	margin-top: 0;	margin-bottom: 0;}
}
