.DayPicker {
  font-size: 1em;
}

.DayPicker-Day {
  color: #7f7f7f;
  font-family: "futura-pt-medium";
  outline: none;
}

.DayPicker-Caption {
  padding: 0 8px;
  margin-bottom: 1em;
  color: #a3c960;
  font-family: "futura-pt-medium";
}

.DayPicker-Weekday {
  padding: 0.8em;
}

.DayPicker-wrapper {
  outline: none;
  padding: unset;
}

.DayPicker-Day--disabled {
    color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #a3c960;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #91b356;
}

.DayPicker-NavButton--prev {
  background-color: #f7f8fc;
  margin-right: 2.5em;
}

.day-picker-navButton-wrapper {
  position: absolute;
  right: 0em;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
  padding-top: 4px;
}
