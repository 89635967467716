.dashboard-ljc {display: flex;padding: 12px 0;font-size: 22px;color: #535353;}
.slick-slide div, .slick-slide div:focus{outline: none;}
.main-container {	display: inherit !important;}
.sidebar-switch-menu {position: absolute;	left: 0;	top: 0; display: none;}
.sidebar-switch-menu:focus{outline: none;}
.sidebar-switch-menu img {	width: 35px;	height: 35px; display: block;}
.sideBarClass{flex:0 0 180px !important; width: inherit !important; transition:all .3s ease;-moz-transition:all .3s ease;-webkit-transition:all .3s ease;-ms-transition:all .3s ease;-o-transition:all .3s ease;}
.sideBarClass .MuiDrawer-paper{background: #4C4C4C;	overflow: inherit !important;transition:all .3s ease;-moz-transition:all .3s ease;-webkit-transition:all .3s ease;-ms-transition:all .3s ease;-o-transition:all .3s ease;}
body.sidebar-close .sideBarClass .MuiDrawer-paper{left:-500px; transition:all .3s ease;-moz-transition:all .3s ease;-webkit-transition:all .3s ease;-ms-transition:all .3s ease;-o-transition:all .3s ease;}
body.sidebar-close .sideBarClass{flex:0 0 0px !important;  transition:all .3s ease;-moz-transition:all .3s ease;-webkit-transition:all .3s ease;-ms-transition:all .3s ease;-o-transition:all .3s ease;}
.main-content-area {	padding: 25px 25px 25px 210px !important; width:100%; max-width: inherit !important;	margin: 0 !important;	flex:0 180 100%; transition:all .3s ease;-moz-transition:all .3s ease;-webkit-transition:all .3s ease;-ms-transition:all .3s ease;-o-transition:all .3s ease;}
body.sidebar-close .main-content-area { padding-left: 24px !important; transition:all .3s ease;-moz-transition:all .3s ease;-webkit-transition:all .3s ease;-ms-transition:all .3s ease;-o-transition:all .3s ease;}


.grids-outer {	margin-bottom: 25px; max-width: 1440px; margin-left:auto; margin-right:auto;}

.grids-outer-dashbard {	margin-top: 25px;	margin-bottom: 25px; max-width: 1440px; margin-left:auto; margin-right:auto;}
.grids-outer-staff {	margin-bottom: 68px !important;	margin-top: -8px; max-width: 1440px; margin-right: auto; margin-left: auto;}
.grid-view-outer {	width: 100%;	padding-top: 0; padding-bottom:0px; 	overflow: hidden;	display: flex;	align-items: center;	justify-content: space-between;}
.utes-circle-outer {	align-items: center;}
.utes-circle-col {	margin: 0 10px !important;	border-radius: 50px !important;	width: 35px;	height: 35px;	line-height: 35px;	font-size: 12px;	padding: 0 !important;}
.dash-box-outer{position: relative;}
.dash-box-outer.day-view::before {	position: absolute;	left: 0;	top: 20px;	content: "";	width: 14px;	height: 14px;	background-size: 14px !important;	background: url(/images/sun-img.png) no-repeat;	right: 0;	margin: 0 auto;	z-index:1;}
.dash-box-outer.night-view::before {	position: absolute;	left: 0;	top: 20px;	content: "";	width: 14px;	height: 14px;	background-size: 14px !important;	background: url(/images/moon-img.png) no-repeat;	right: 0;	margin: 0 auto;	z-index: 1;}
.dash-box-outer .grid-view-outer-block{position: relative;}
.dash-box-outer .job-details-id {padding: 5px 38px;margin: 0;border-radius: 8px 0 8px 0;font-size: 12px;position: absolute;left: 0;top: 0; min-width: 150px;}
.dash-box-outer.list-view .list-view-outer-block{position: relative; }
.dash-box-outer.list-view .list-view-outer-block .job-details-id + div{padding-top: 35px !important;}
.dash-box-outer.list-view .list-view-outer-block .list-title-area .job-details-id + div{padding-top: 35px !important;}
.dash-box-outer.list-view .list-view-outer-block .list-table-area {	padding-right: 45px !important; align-items: center;}
.dash-box-outer.list-view .list-view-outer-block .list-action-area{}
.dash-box-outer.list-view .jobs-details-list-tbc {
	position: absolute;
	right: 100px;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	margin: 0;
	width: 28px;
	height: 28px;
	padding: 0;
	border-radius: 50%;
	max-width: inherit;
	min-width: inherit;
	line-height: 28px;
}
.dash-box-outer.list-view.day-view::before, .dash-box-outer.list-view.night-view::before {	left: inherit;	top: 50%;	right: 60px;	transform: translateY(-50%);-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);}
.shift-status-grid-view {	position: absolute;	left: 0;	top: 12px;	text-align: center;	width: 100%;}
.shift-status-grid-view img {	display: inline-block;	height: 14px;}
.shift-status-list-view {	align-items: center;	display: flex;}
.shift-status-list-view img {	display: inline-block;	height: 28px;}
.light-gray-btn {	background: #D8D8D8 !important;	color: #535353 !important;}
.light-gray-btn:hover, .light-gray-btn:focus {	background: rgba(0, 0, 0, 0.04) !important;}
.bg-blue{background: #3399CC !important;}
.search-view-type button {
	padding: 0;
	width: 35px;
	height: 35px;
	border: 1px solid #B3B3B3;
	text-align: center;
	line-height: 35px;
  margin-left: 15px;
}
.search-view-type button:first-child {margin-left: 0;}
.search-view-type button svg{width:19px;}
.search-view-type button path{width: 100%; height: 100%; fill:#B3B3B3;}
.search-view-type button.search-tile-active{background-color: #8FBE3E; border-color: #8FBE3E;}
.search-view-type button.search-tile-active path{fill:#fff;}
.search-job-input-outer{padding-top: 0 !important; padding-bottom: 25px !important; }
/* .MuiPaper-rounded .MuiDialogContent-root{border-bottom-left-radius: 12px !important; border-bottom-right-radius: 12px !important; overflow: hidden !important;}     */  
.MuiPaper-rounded .popup-content-rounded .MuiDialogContent-root{border-bottom-left-radius: 12px !important; border-bottom-right-radius: 12px !important; overflow: hidden !important;}    
.filter-type {
	display: flex;
	align-items: center;
	margin-right: auto;
	padding-left: 35px;
	flex: 0 0 350px;
	max-width: 350px;
}

.filter-type span {
	flex-basis: 110px;
	text-align: left;
	text-transform: uppercase;
}

@media screen and (max-width: 1279px) {
.main-content-area {	padding: 25px 20px 25px 212px !important;}
.dash-box-outer.list-view .list-view-outer-block > div{flex-direction: column;}
.list-title-area {	max-width: inherit !important;	width: 100%;}
.dash-box-outer.list-view .list-view-outer-block .list-table-area {padding-right: 0px !important;flex: inherit !important;padding-left: 0px !important; flex-wrap: wrap;}
.dash-box-outer.list-view .list-view-outer-block .list-table-area .job-details-info-list-wrapper {    border-bottom: 1px solid #ddd;    padding: 15px 0;    width: 33.33% !important;
}
.dash-box-outer.list-view .list-view-outer-block .list-table-area .job-details-info-list-divider{display: none;}
.dash-box-outer.list-view .jobs-details-list-tbc {	position: relative;	right: 0;	top: 10px;	transform: inherit;	margin: 0;}
.dash-box-outer.list-view.day-view::before, .dash-box-outer.list-view.night-view::before {	left: inherit;	top: 25px;	right: 25px;	transition: inherit;}
.grids-outer {	margin-bottom: 20px;}
.grids-outer-dashbard {	margin-top: 20px;	margin-bottom: 20px;}
.grids-outer-staff{		margin-bottom: 12px;}
.filter-type {
	padding-left: 15px;
	flex: 0 0 235px;
	max-width: 235px;
}
}




@media screen and (max-width: 767px) {
.dash-box-outer.list-view .list-view-outer-block .list-table-area .job-details-info-list-wrapper {	width: 50% !important;}
.main-content-area {	padding: 25px 20px 25px 20px !important;}
.sideBarClass .MuiDrawer-paper{ left: -800px; width: 65%; box-shadow: 0 0 5px rgba(0,0,0,.6);}
body.sidebar-close .sideBarClass .MuiDrawer-paper {	left:0px;}
body.sidebar-close .main-content-area { padding-left: 20px !important;}
.grid-view-outer > div{margin-bottom: 15px;}
.dash-box-outer .job-details-id {	padding: 5px 15px;}
.sidebar-switch-menu {display: block;}
.grids-outer-dashbard {	margin-top: 10px;	margin-bottom: 10px;}
.staff-member-btn {	width: calc(100% - 30px);	max-width: inherit;}
.staff-member-btn .assign-staff-btn-outer button {	width: 100% !important;}

.grid-view-outer{ flex-wrap: wrap;}
.filter-type {
	padding-left: 0;
	flex: 0 0 100%;
	max-width: 100%;
	order: 3;
}
}
