.drag-n-drop-box {width: 330px; border-radius: 6px;background-color: white;}
.drag-drop-wrapper {padding: 0px 0 0;color: #535353;width: max-content;}
.drag-drop-title-wrapper {text-align: left;}
.drag-n-drop-text-wrapper {display: flex;width: max-content;margin: auto;padding: 20px 0;}
/* .drag-n-drop-text-wrapper ol {list-style: decimal;margin: -28px;font-size: 18px;max-height: 75px;overflow-y: auto;text-align: left;} */
.drag-n-drop-text-wrapper ol {list-style: decimal;margin: 0 0;font-size: 14px;max-height: inherit;overflow-y: inherit;text-align: left;}
.drag-n-drop-text-wrapper ol  li p{padding-top:12px !important; padding-bottom:12px !important;}
.drag-n-drop-or-wrapper {display: flex;width: 55%;margin: 0 0 0 auto;padding-top: 14px;}
.drag-n-drop-or-upload-button-wrapper {width: 100%;}

.drag-drop-outer .drag-drop-wrapper{width: 100%;}
.drag-drop-outer .drag-drop-wrapper .drag-n-drop-box{width: 100%;}
.drag-drop-outer .drag-drop-wrapper .drag-n-drop-box .drag-n-drop-text-wrapper{justify-content: center;align-items: center;height: 100%;width: 100%;}
.drag-drop-outer .drag-drop-wrapper .drag-n-drop-box .drag-n-drop-text-wrapper p {background: url(/images/doc-icon.png) left center no-repeat;display: inline-block;background-size: 25px;padding: 20px 0 20px 35px;}
.drag-drop-outer .drag-n-drop-or-wrapper {	justify-content: flex-end;	align-items: center;}
.drag-drop-outer .drag-n-drop-or-upload-button-wrapper {	width: auto;	margin-left: 15px;}

.drag-drop-outer .drag-drop-wrapper .drag-n-drop-box .drag-n-drop-text-wrapper.uploaded-file-list {	justify-content: flex-start; padding: 5px 0;}
