.job-details-id {
  color: white;
  padding: 8px;
  margin: 8px;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 12px;
}

.incomplete {
  background-color: #ff4141;
}

.in-progress {
  background-color: #ffaa2a;
}

.completed {
  background-color: #31ae45;
}

.job-details-info-title {
  color: #bbbbbb;
  font-size: 12px;
  text-transform: uppercase;
}

.job-details-info-wrapper {
  width: 33.3%;
  text-align: left;
}

.job-details-info-data {
  color: #7f7f7f;
  font-size: 12px;
}

.job-details-info-divider {
  padding: 0 8px;
  margin: 8px auto;
  width: calc(100% - 26px);
  height: 1px;
  background-color: rgba(146, 149, 154, 0.25);
}

.jobs-details-utcs-wrapper {
  display: flex;
  flex-grow: 1;
}

.jobs-details-utcs-title {
  color: #bbbbbb;
  font-size: 12px;
}
.jobs-details-utcs-data {
  color: #7f7f7f;
  font-size: 12px;
  margin: 0 4px;
  display: flex;
}

.jobs-details-tbc {
    background-color: #ffe100;
    color: #535353;
    font-size: 12px;
    letter-spacing: 0.01px;
    padding: 0px 8px;
    font-family: "futura-pt-bold";
    border-radius: 20px;
}

.job-details-view-job-report-wrapper {
  padding: 12px;
  color: green;
  cursor: pointer;
}
