.add-staff-signature-wrapper {
    padding: 15px 24px;
    text-align: left;
    background: white;
    width: 225px;
    border-radius: 12px;
    position: absolute;
}

.add-staff-registered-name {
    font-family: "futura-pt-demi";
    font-size: 16px;
    line-height: 21px;
    color: #535353;
    padding: 4px 0;
}

.add-staff-staff-name {
    font-family: "futura-pt-book";
    font-size: 16px;
    line-height: 21px;
    color: #535353;
    padding: 4px 0;
}

.add-staff-signature-inner-wrapper {
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    padding: 24px 0

}

.add-staff-signature-img-wrapper {
    width: 124px;
    height: 74px;
    margin: auto;
}

.add-staff-signature-img {
    width: 124px;
}

.address-section {
    text-align: left;
    cursor: pointer;
}

.address-suggestion {
    background-color: #fff;
    padding: 1px 5px 0;
    font-size: 12px;
    color: #7f7f7f;
}


.text-danger {
    color: #FF4141;
    text-align: left;
  }

  .displayDiv {
  display: block;
  }
  .client-sub-btn button {
  	width: 100%;
  }
    .client-sub-btn{margin-top: 30px;}
  @media screen and (max-width: 1279px) {

}
