.add-new-emailtemplate {
  color: white;
  font-size: 20px;
}
.add-new-emailtemplate-list {
    padding: 3px 8px;
}

.new-emailtemplate-main-wrapper {
  margin: auto;
  color: white;
  font-size: 1.5em;
  padding: 24px 0;
}

.new-emailtemplate-inner-wrapper {
  margin: auto;
}
.new-emailtemplate-inner-wrapper-list {
  height: 42px;
}

.new-emailtemplate-main-wrapper-list {
  margin: auto auto 0 0;
  display: flex;
  padding: 0px 8px;
}
