.staff-member-id-wrapper {
  color: white;
  padding: 8px;
  margin: 8px;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #8fbe3e;
}

.staff-member-name {
  font-size: 20px;
  color: #535353;
  padding: 8px 24px;
}

.staff-member-field-title {
  color: #bbbbbb;
  padding: 14px 0px
}

.staff-member-current-job {
  color: #8FBE3E;
  padding: 14px 10px;
  text-decoration: underline;
}

.staff-mini-divider {
  width: 2px;
  height: 7px;
  background: #979797;
  margin: 21px 10px 0 0px;
}

.staff-member-default-value {
  color: #7F7F7F;
  padding: 14px 10px;
}

.staff-member-location-edit-wrapper {
display: flex;
width: max-content;
margin: 0 0 0 auto;
flex-direction: row-reverse;
}

.staff-member-location-wrapper {
margin: 8px 24px;
}

.staff-member-location {
  width: 18px;
}

@media screen and (max-width: 1279px) {
.staff-list-outer { 	flex-wrap: wrap;  }
.staff-mini-divider{display: none;}
.staff-member-id-wrapper {	width: 100%;}
.staff-member-name {	width: 100%;}
.staff-member-field-title, .staff-member-current-job, .staff-member-default-value {	width: 50%;}
.staff-member-location-edit-wrapper {	margin: 0;	width: 100%;	flex-direction: column-reverse;	padding-bottom: 15px;}
.staff-member-location-edit-wrapper a, .staff-member-location-edit-wrapper a button {width: 100%;margin: 0;}
}
