@font-face {
  font-family: 'futura-pt';
  src: url('fonts/Futura PT/FuturaPTLight.otf');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'futura-pt-demi';
  src: url('fonts/Futura PT/FuturaPTDemi.otf');
  font-style: normal;
  font-weight: 100;
}  
@font-face {
  font-family: 'futura-pt-medium';
  src: url('fonts/Futura PT/FuturaPTMedium.otf');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'futura-pt-bold';
  src: url('fonts/Futura PT/FuturaPTBold.otf');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'futura-pt-book';
  src: url('fonts/Futura PT/FuturaPTBook.otf');
  font-style: normal;
  font-weight: 100;
}


h4 {
    margin: 0;
    font-family: 'futura-pt-demi';
}

p {
  margin: 0;
  font-family: 'futura-pt-medium';
}