.emailtemplate-outer {
	width: 100%;
	padding: 15px 125px 15px 15px;
	border-radius: 8px;
	background-color: white;
	font-family: 'futura-pt-medium';
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: left;
	position: relative;
}
.emailtemplate-title {
	font-size: 18px;
}
.emailtemplate-content {
	color: #535353;
	font-size: 14px;
}
.emailtemplate-actions {
	position: absolute;
	right: 30px;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}
.emailtemplate-actions a {
	margin-left: 25px;
	color: rgb(143, 190, 62);
}
.emailtemplate-actions a:first-child {
	margin-left: 0;
}


@media screen and (max-width: 600px) {
	.emailtemplate-outer {
		padding: 10px 10px 10px 10px;
	}
  .emailtemplate-actions {
  	position: static;
  	transform: inherit;
  	margin-top: 5px;
  }
}
