.add-new-job-input-tf {border: none;border-radius: 25px;padding: 4px 20px;outline: none;font-family: futura-pt-medium;font-size: 14px;color: #7f7f7f;width: 100%;height: 36px;}
input.add-new-job-input-tf::-webkit-input-placeholder { color: #535353;}
input.add-new-job-input-tf:-ms-input-placeholder { color: #535353;}
input.add-new-job-input-tf::placeholder { color: #535353;}
.add-new-job-field-title {color: #535353;font-size: 16px;padding: 8px 0;text-align: left;}
.add-new-job-input-select {border: none;border-radius: 25px;padding: 4px 30px 4px 15px;outline: none;height: 36px;font-family: futura-pt-medium;font-size: 14px;color: #7f7f7f;width: 100%;-webkit-appearance: none !important;-ms-appearance: none !important;appearance: none !important;background-image: url(/images/select-arrow-black.png);background-position: calc(100% - 20px) center;background-repeat: no-repeat;background-size: 11px; background-color:#fff ;}
.add-new-job-input-textarea {border: none;border-radius: 10px;padding: 4px 28px 4px 8px;outline: none;font-family: futura-pt-medium;font-size: 14px;color: #7f7f7f;width: 100%;resize: vertical;min-height: 95px;}
.add-new-job-field-cb-wrapper {text-align: left;color: #7f7f7f;line-height: 18px;font-family: "futura-pt-medium";display: flex;}
.add-new-job-field-cb {width: 18px;height: 18px;}
.add-new-job-field-cb-label {flex: 1;align-items: center;display: flex;}
.anj-staff-member-wrapper {display: flex;flex-wrap: wrap;flex: 1;}
.anj-staff-member-item {/* padding: 0 8px 8px 8px; */padding: 0;}
.anj-staff-new-staff-wrapper {display: flex;flex-wrap: wrap;flex: 1;}
.anj-create-job-wrapper {position: relative;height: 100%;}
.anj-create-job-inner-wrapper {position: absolute;width: 100%;bottom: 0;}
.anj-add-circle-wrapper {height: 32px;padding-top: 5px;margin: 0 4px;}
input:disabled {color: white;background: #727272;}
select:disabled {color: white;background: #727272;}
textarea:disabled {color: white;background: #727272;}
input::placeholder {color: #e0e0e0;}
input:disabled::placeholder,select:disabled::placeholder,select:disabled::placeholder {color: #fff !important;}
.job-id-right.large-dark-block {	background: #727272;	border: 1px solid #727272;	font-size: 18px;	height: 36px;	border-radius: 25px;	padding: 0 40px;	line-height: 34px;	color: #fff;}
.job-id-right.large-dark-block.large-light-block {  background: #fff;  border: 1px solid #BFBFBF; color: #7F7F7F;}
.job-id-right.large-dark-block.large-light-block .job-id-right span { color: #bbb;}
.text-danger {  color: #FF4141;  text-align: left;}
.displayDiv {display: block;}
.add-job-radio-btn .radio-custom:last-child{margin-left: 50px;}

.grid-outer-add {
	display: flex;
	flex-wrap: wrap;
}
.addPageLeft {
	width: calc(66.66% + 30px);
	padding-left: 30px;
	display: flex;
	flex-wrap: wrap;
	margin-left: -30px;
}
.addPageRight {	width: 33.33%;	padding: 0 30px;}
.addPageLeft .MuiGrid-grid-xs-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-4{ max-width: 50%; flex-basis: 50%; padding: 16px 30px;}
.addPageRight .MuiGrid-grid-xs-12{ padding-top: 16px;}

.grid-outer-add.job-page-edit .addPageLeft {	width: calc(100% + 30px);}
.grid-outer-add.job-page-edit .addPageLeft .MuiGrid-grid-xs-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-4 {	max-width: 33.33%;	flex-basis: 33.33%;	padding: 16px 30px;}

.grid-form-group{	max-width: 33.33%;	flex-basis: 33.33%;	padding: 16px 30px;}
.job-form-group.w-60{ width: 66.66%;}
.relative{position: relative;}
.add-new-job-field-title.add-alternate-email { margin-top: 5px;  margin-bottom: -40px;}
.add-new-job-additional-email { position: relative;}
.add-new-job-additional-email a { position: absolute; padding-left: -9px; right: 10px; top: 50%; transform: translateY(-50%); -ms-transform: translateY(-50%); -webkit-transform: translateY(-50%);}
.add-new-job-additional-email a img{display: block;}
.add-new-job-additional-email input{padding: 4px 40px 4px 20px;}

.rc-time-picker {
	width: 100% !important;
}
.rc-time-picker .rc-time-picker-input {

	padding: 5px 20px !important;
	height: 36px !important;
	border: 0px solid #d9d9d9 !important;
	border-radius: 20px !important;
  outline: none !important;
}
.rc-time-picker-clear{
	display: none !important;
}
.rc-time-picker .rc-time-picker-clear {
	right: 12px !important;
	top: 7px !important;
}

@media screen and (max-width: 1279px) {
.form-blank-div{display: none;}
.job-sub-btn {	margin-top: 50px !important;}
.add-new-job-field-title.add-alternate-email {	margin-top: 2px;	margin-bottom: -35px;	font-size: 15px;}
}

@media screen and (max-width: 1200px) {
  .addPageLeft {	width: calc(50% + 30px);}
  .addPageLeft .MuiGrid-grid-xs-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-4 {	max-width: 100%;	flex-basis: 100%;}
  .addPageRight {	width: 50%;}
  .grid-outer-add.job-page-edit .addPageLeft .MuiGrid-grid-xs-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-4 {max-width: 50%; flex-basis: 50%; }
}
@media screen and (max-width: 991px) {
.grid-calender .add-new-job-dates-picker-wrapper .picker {	position: relative !important; min-height: auto !important;}
.addPageLeft {	width: calc(100% + 30px);}
  .addPageRight {	width: 100%;}
.grid-outer-add.job-page-edit .addPageLeft .MuiGrid-grid-xs-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-4 {	max-width: 100%;	flex-basis: 100%; }
.add-new-job-field-title.add-alternate-email {margin-bottom: -25px;}
}
@media screen and (max-width: 960px) {

}
@media screen and (max-width: 767px) {
  .MuiGrid-spacing-xs-4 {	width: calc(100% + 0px) !important;	margin: 0 0px !important;}
  .MuiGrid-spacing-xs-4 > .MuiGrid-item {	padding: 16px 0px !important;}
  .job-sub-btn button{ width: 100%;}
  .addPageLeft .MuiGrid-grid-xs-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-4{padding-left: 0; padding-right: 0;}
	.grid-outer-add.job-page-edit .addPageLeft .MuiGrid-grid-xs-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-4 {padding-left: 0; padding-right: 0;}   
  .addPageRight {	width: 100%; padding:0 0;}
	.grid-form-group {padding: 16px 0px;}
}
