.login-main-wrapper {margin: auto;background: white;}
.login-elements-wrapper {text-align: center;margin: auto;height: 100%;}
.login-elements-inner-wrapper {position: absolute;top: 50%;left: 50%;height: 30%;width: 50%;margin: -15% 0 0 -25%;}
.login-textfield-wrapper {width: 400px;margin: auto;padding: 12px;}
.login-confirm-button-wrapper {width: 250px;margin: auto;padding: 12px;}
.login-title-wrapper {margin: auto;padding: 12px;}
.login-title {color: red;font-family: "Lato", sans-serif;}
.login-rem-for-wrapper {display: flex;font-family: "Roboto", "Helvetica", "Arial", sans-serif;font-size: 14px;line-height: 2;color: #7f7f7f;}
.login-for-item-wrapper p{font-family: "Roboto", "Helvetica", "Arial", sans-serif;}
.login-rem-item-label-wrapper {display: flex;}
.login-rem-item-wrapper {flex: 1 1;text-align: left;display: flex;align-items: center;}
.login-for-item-wrapper {flex: 1;text-align: right;}
.login-textfield-input {border: none;padding: 4px 0px;outline: none;font-family: "futura-pt-demi";font-size: 18px;color: #ffffff !important;width: 100%;background-color: transparent;border-bottom: 1px solid; border-radius: 0;}
.login-submit-button-wrapper {  padding:0;}
.login-submit-button-wrapper button:hover, .login-submit-button-wrapper button:focus{background-color: rgba(255, 255, 255, 1);
color:#88BB41;}
.login-img-wrapper {  width: 100%;  height: auto;  margin-bottom: 55px;  max-width: 446px;}
.loginContainer .MuiContainer-root{height:100vh; display: flex; align-items: center;}
.loginContainer .MuiContainer-root .MuiBox-root{	position: absolute;	left: 0;	bottom: 15px;	width: 100%;	text-align: center;}
.loginContainer .MuiContainer-root .MuiBox-root p{font-size: 11px;}
.login-textfield-input::-webkit-input-placeholder {  color: #ffffff !important; opacity: .8;}
.login-textfield-input:-ms-input-placeholder { color: #ffffff !important;  opacity: .8;}
.login-textfield-input::placeholder {  color: #ffffff !important;  opacity: .8;}
.loginContainer {	background-size: 100% 100% !important; overflow: hidden;}

@media screen and (max-width: 1400px) {
  .login-img-wrapper {	margin-bottom: 45px; }
}
@media screen and (max-width: 991px) {
.loginContainer .MuiContainer-root {max-width: 500px;}
.loginContainer .MuiContainer-root .makeStyles-paper-7 .login-img-wrapper {width: 250px;}
.loginContainer .loginContainerInner{padding:25px; }
}
@media screen and (max-width: 767px) {
.loginContainer .MuiContainer-root .makeStyles-paper-7 {padding: 15px;width: 100%;}
.loginContainer .loginContainerInner{padding:15px; width: 100%;}
.loginContainer .MuiContainer-root .makeStyles-paper-7 .login-img-wrapper {margin-bottom: 15px;}
.loginContainer .MuiGrid-spacing-xs-4 > .MuiGrid-item {padding: 12px 16px;}

}

@media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) {
  .login-img-wrapper {	margin-bottom: 10px;}
.loginContainer .MuiContainer-root {align-items: flex-start;padding-top: 50px;padding-bottom: 50px;}
.loginContainer .MuiContainer-root > .makeStyles-paper-7{padding:0;}
.loginContainer {	height: auto !important; overflow: inherit !important;}
.loginContainer .MuiContainer-root {height: auto !important;}
.loginContainer .MuiContainer-root .MuiBox-root {	position: static;	margin-top: 15px;}
}
