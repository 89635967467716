.dates-rail-wrapper {
	max-width: 500px;
	margin: 12px auto;
  }
  .calendar-block{flex: inherit;}
  .slick-slider .slick-list{margin: 0 25px; max-width: 75vw; }
  .slick-slider .slick-list .slick-slide .dates-date-wrapper .date-selected{color:#8f8f8f; font-size: 14px;}
  .dates-date-wrapper .date-selected-today{color:#535353 !important; font-size: 12px;}
  .dates-date-wrapper .date-selected-label{color:#8FBE3E !important;}

  .slick-slider button{transform: inherit;}

  .slick-prev.slick-disabled::before, .slick-next.slick-disabled::before {
	  opacity: 1 !important;
  }
  .slick-prev{
		  left:0 !important;

	  }
	.slick-next {
		  right:0 !important;
		}
  .slick-prev::before, .slick-next::before {
		  opacity: 1 !important;
	  }

    .calendar-block .dates-rail-wrapper .slick-prev:hover::before, .calendar-block .dates-rail-wrapper .slick-next:hover::before{color:#8FBE3E;}

@media screen and (max-width: 991px) {
  .slick-slider .slick-list{max-width: 75vw; }
}