.add-new-job {
  color: white;
  font-size: 20px;
}
.add-new-job-list {
    padding: 3px 8px;
}

.new-job-main-wrapper {
  margin: auto;
  color: white;
  font-size: 1.5em;
  padding: 24px 0;
}

.new-job-inner-wrapper {
  margin: auto;
}
.new-job-inner-wrapper-list {
  height: 42px;
}

.new-job-main-wrapper-list {
  margin: auto auto 0 0;
  display: flex;
  padding: 0px 8px;
}
